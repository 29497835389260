$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.waitlist-form__input {
  @include input-reset;
  @include font($body-medium);

  background-color: $grey-100;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  line-height: 22px;
  margin: 10px 0;
  padding: $spacer-s;
  width: 100%;

  &--success {
    outline: 1px solid $green-500;
  }
}
