$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

$product-overlay-spacer-lg: 40px;

.product-overlay {
  &__toggle {
    padding: 0;
    text-align: left;
  }

  div &__size-details {
    border-bottom: 0;
    min-height: 24px;

    @include breakpoint(tablet) {
      margin-top: 0;
      min-height: unset;
      padding: 0;
    }

    p {
      @include font($body-small);

      color: $black;
    }

    & > div {
      border-bottom: none;
      padding: 0;

      @include breakpoint(tablet) {
        padding: 0;
      }
    }

    // hacky way of getting .model-message-body
    & > div > p > div {
      padding: $spacer-sm $spacer-s;
    }
  }

  &__card-header {
    border-bottom: none;
    box-sizing: border-box;
    display: block;
    padding: $spacer-s;

    &-title {
      @include font($heading-medium);

      margin-left: 0;
      margin-right: $spacer-sm;
      padding-right: 0;
    }
  }

  &__cta-container {
    animation: fadeIn 0.5s ease-in-out 1s forwards;
    background-color: white;
    border-top: 1px solid $grey-200;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin-top: $spacer-m;
    opacity: 0;
    padding: $spacer-s;
    position: sticky;
    z-index: 30;

    @include breakpoint(tablet) {
      animation: none;
      border: none;
      box-sizing: border-box;
      opacity: 1;
      padding: 0;
      position: relative;
    }

    .styles_product-cta__final-sale {
      margin-top: $spacer-xs;
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  &__cta-button {
    @include font($button);

    height: 40px;

    @include breakpoint(tablet) {
      height: 48px;
      width: 100%;
    }
  }

  &__cta-wrapper {
    flex-flow: row wrap !important;
    margin: 0;
    min-height: 40px;
    padding: 0 !important;
    width: 100%;

    @include breakpoint(desktop) {
      min-height: 48px;
    }
  }

  &__details-button-link {
    @include font($button);

    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: $spacer-xs;
    outline: 1px solid $grey-200;
    text-align: center;
    text-decoration: none;
    width: calc(50% - $spacer-xs);

    @include breakpoint(tablet) {
      height: 48px;
      margin-top: $spacer-xs;
      width: 100%;
    }
  }

  &__flyout {
    position: absolute;

    @include breakpoint(desktop) {
      width: 1064px;
    }

    &__close-button {
      height: $product-overlay-spacer-lg;
      position: fixed;
      width: $product-overlay-spacer-lg;
    }
  }

  &__desktop-gallery {
    display: flex;
  }

  &__card-close-button {
    position: absolute;
    right: 0;
    top: $spacer-s;
  }

  &__waitlist-form {
    margin-left: $spacer-s;
    margin-right: $spacer-s;

    & > input {
      background-color: $grey-100;
      border: none;
    }
  }

  &__buttons {
    display: flex;
  }

  &__sale {
    @include font($body-medium);

    color: $grey-500;
  }

  &__final-sale {
    color: #ca3214;
    margin-top: 12px;
    text-align: center;
  }

  &__desktop-layout {
    display: flex;
    max-width: 90vw;

    @include breakpoint(desktop) {
      max-height: 100vh;
      max-width: 1064px;
      overflow-y: hidden;
    }
  }

  &__gallery-container {
    box-sizing: border-box;
    flex: 1;
    max-width: 600px;
  }

  &__details {
    &-container {
      box-sizing: border-box;
      flex: 1;
      max-width: 464px;
      padding: 104px $product-overlay-spacer-lg 0 $product-overlay-spacer-lg;

      @include breakpoint(tablet) {
        max-height: 100vh;
        overflow-y: scroll;
        padding-bottom: $product-overlay-spacer-lg;
        position: relative;
      }
    }
  }
}
